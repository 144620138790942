<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data">
            <template #educationLeisureFacilities>
              <div class="pt-2">
                <draggable
                  v-model="tempFacilities"
                  :move="onMove"
                  :start="true"
                  :end="true"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <b-col
                      lg="12"
                      md="12"
                      class="p-0"
                      v-for="(feature, idx) in tempFacilities"
                      :key="feature.id"
                    >
                      <b-row>
                        <b-col lg="12">
                          <label
                            >{{ $t("field.image") }}
                            <span class="text-muted">
                              - {{ $t("field.optional") }}
                            </span>
                          </label>
                          <n-async-single-image-uploader
                            :key="`image-upload-${idx}-${featureKey}`"
                            path="education-leisure-facility"
                            ph="240"
                            pw="240"
                            :disableUploadHistory="true"
                            :fullWidth="false"
                            :image="feature.image"
                            v-model="feature.imageId"
                            @change="onUploadChange($event, idx, 'facility')"
                          />

                          <b-row>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${feature.nameEn}`"
                                :name="$t('field.nameEn')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.nameEn") }}
                                </label>
                                <b-form-input
                                  :placeholder="$t('field.nameEn')"
                                  autocomplete="off"
                                  v-model="feature.nameEn"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-col>
                            <b-col lg="6">
                              <validation-provider
                                #default="{ errors }"
                                :vid="`${feature.nameKm}`"
                                :name="$t('field.nameKm')"
                                rules="required"
                              >
                                <label class="mt-2"
                                  >{{ $t("field.nameKm") }}
                                </label>
                                <b-form-input
                                  :placeholder="$t('field.nameKm')"
                                  autocomplete="off"
                                  v-model="feature.nameKm"
                                  :class="
                                    errors.length > 0 ? 'is-invalid' : null
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col align-self="end" class="text-right">
                          <b-button
                            variant="outline-danger"
                            class="mt-1"
                            @click="removeFeature(idx)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-1"
                            ></feather-icon>
                            <span>{{ $t("button.delete") }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <hr class="mb-2" />
                    </b-col>
                  </transition-group>
                </draggable>
                <b-col class="p-0">
                  <b-button
                    variant="primary"
                    class="mb-2"
                    @click="addMoreFeature"
                  >
                    <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                    <span>{{ $t("button.addFacility") }}</span>
                  </b-button>
                </b-col>
              </div>
            </template>

            <template #educationLeisureGalleries>
              <div class="mt-2">
                <draggable
                  v-model="tempGalleries"
                  :move="onMove"
                  :start="true"
                  :end="true"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <div
                      class="list-group-item"
                      v-for="(gallery, idx) in tempGalleries"
                      :key="gallery.id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="`${gallery.id}-${idx}`"
                        :name="$t('field.image')"
                        rules="required"
                      >
                        <n-async-single-image-uploader
                          :key="`image-upload-${idx}-${key}`"
                          path="education-leisure-gallery"
                          ph="240"
                          pw="240"
                          :disableUploadHistory="true"
                          :disableSpacer="false"
                          :fullWidth="false"
                          :image="gallery.image"
                          v-model="gallery.imageId"
                          @change="onUploadChange($event, idx, 'gallery')"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-async-single-image-uploader>
                        <feather-icon
                          @click="removeGallery(idx)"
                          class="close-icon"
                          icon="XIcon"
                          size="18"
                        ></feather-icon>
                      </validation-provider>
                    </div>
                  </transition-group>
                </draggable>
                <hr v-show="tempGalleries.length >= 1" />
                <b-button
                  variant="primary"
                  @click="addMoreGallery"
                  class="mb-2"
                >
                  <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                  <span>{{ $t("button.addGallery") }}</span>
                </b-button>
              </div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                v-if="$can('create', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BFormInput, BButton } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
import draggable from "vuedraggable";

const EducationLeisureRepository = Repository.get("educationLeisure");

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    draggable,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    FeatherIcon,
    NAsyncSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      key: 1,
      featureKey: 1,
      index: 1,
      featureIndex: 1,
      tempFacilities: [],
      tempGalleries: [],
      data: {
        bannerId: null,
        nameEn: null,
        nameKm: null,
        link: null,
        videoUrl: null,
        sequenceOrder: null,
        isEnable: true,

        titleEn: null,
        descriptionEn: "",
        metaTitleEn: null,
        metaDescriptionEn: null,
        contactInformationEn: "",

        titleKm: null,
        descriptionKm: "",
        metaTitleKm: null,
        metaDescriptionKm: null,
        contactInformationKm: "",
        educationLeisureContents: [],
        educationLeisureFacilities: [],
        educationLeisureGalleries: [],
      },
      loading: false,
    };
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onUploadChange(event, idx, type) {
      if (type === "facility") {
        const index = this.tempFacilities.findIndex((element, i) => {
          return idx == i;
        });
        if (index != -1) {
          this.tempFacilities[index].image = event.fileUrl;
        }
      } else {
        const index = this.tempGalleries.findIndex((element, i) => {
          return idx == i;
        });
        if (index != -1) {
          this.tempGalleries[index].image = event.fileUrl;
        }
      }
    },
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      this.data.educationLeisureContents.push(
        {
          locale: "en",
          title: this.data.titleEn,
          description: this.data.descriptionEn,
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
          contactInformation: this.data.contactInformationEn,
        },
        {
          locale: "km",
          title: this.data.titleKm,
          description: this.data.descriptionKm,
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
          contactInformation: this.data.contactInformationKm,
        }
      );

      this.tempFacilities.forEach((feature, index) => {
        this.data.educationLeisureFacilities.push(
          {
            locale: "en",
            imageId: feature.imageId,
            sequenceOrder: index + 1,
            name: feature.nameEn,
          },
          {
            locale: "km",
            imageId: feature.imageId,
            sequenceOrder: index + 1,
            name: feature.nameKm,
          }
        );
      });
      this.tempGalleries.forEach((gallery, index) => {
        this.data.educationLeisureGalleries.push({
          imageId: gallery.imageId,
          sequenceOrder: index + 1,
        });
      });

      EducationLeisureRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    addMoreFeature() {
      this.featureIndex++;
      let feature = {
        id: this.featureIndex,
        imageId: null,
        image: "",
        nameEn: "",
        nameKm: "",
      };
      this.tempFacilities.push(feature);
    },
    removeFeature(idx) {
      this.tempFacilities.splice(idx, 1);
      this.featureKey++;
    },
    addMoreGallery() {
      this.index++;
      let gallery = {
        id: this.index,
        imageId: null,
        image: "",
      };
      this.tempGalleries.push(gallery);
    },
    removeGallery(idx) {
      this.tempGalleries.splice(idx, 1);
      this.key++;
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "education-leisure";
    const route = "education-leisure";

    return { fields, resource, route };
  },
};
</script>
